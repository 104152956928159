
import {computed, defineComponent, onMounted, onUpdated, ref} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import AllTasks from "@/views/task/AllTasks.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import store from "@/store";
import FormTask from "@/views/task/FormTask.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Row from "@/components/base/common/Row.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import PendingTasks from "@/views/task/PendingTasks.vue";
import CompletedTasks from "@/views/task/CompletedTasks.vue";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import BaseForm from "@/components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import TaskService from "@/core/services/TaskService";
import Swal from "sweetalert2";
import Audits from "@/views/audit/Audits.vue";
import {LoadPanel} from "@/core/composite/composite";
import CompleteReminder from "@/views/reminder/CompleteReminder.vue";
import PendingReminder from "@/views/reminder/PendingReminder.vue";
import AllReminder from "@/views/reminder/AllReminder.vue";
import ReminderForm from "@/views/reminder/ReminderForm.vue";
import ReminderService from "@/core/services/ReminderService";
import CompleteReminderForm from "@/views/reminder/CompleteReminderForm.vue";

export default defineComponent({
  name: "Tasks",
  components: {
    CompleteReminderForm,
    ReminderForm,
    AllReminder,
    PendingReminder,
    CompleteReminder,
    Audits,
    Froala,
    BaseForm,
    CompletedTasks,
    PendingTasks, Avatar, Row, EntityLoading, FormTask, BaseModal, AllTasks, QuickAction
  },
  props: {
    objectId: {type: String},
    objectType: {type: String},
  },
  setup() {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      console.log(link);
      if (link) {
        link.classList.remove("active")
      }
    })
    onMounted(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    store.commit('SET_SHOW_TASK_DETAIL', false)
    const user = computed(() => store.getters.currentUser);
    const task = computed(() => store.state.TaskModule.selectedTask)
    const all = computed(() => store.state.TaskModule.allPage);
    const pending = computed(() => store.state.TaskModule.pendingPage);
    const completed = computed(() => store.state.TaskModule.completedPage);

    const reminder = computed(() => store.state.ReminderModule.selectedReminder)
    store.commit(Mutations.SET_REMINDER, {show: false, data: {}, loading: true})

    const state = ref<any>({
      submitting: false,
      model: {
        name: '',
        status: 'NOT_SET',
        description: '',
        dueDate: new Date().getTime(),
        cazeReqId: '',
        claimReqId: '',
        invoiceReqId: '',
        assigneeReqId: '',
        processReqId: '',
        logReportReqId: '',
        teamReqId: '',
        assignType: 'USER'
      },
      complete: {duration: 5, completedNote: ''},
      id: '',
      title: 'Add Task',
      reminder: {
        name: '',
        dueDt: '',
        description :'',
        claimIdRequest: '',
        assignType: 'USER',
        cazeIdRequest: '',
        assigneeIdRequest: '',
        teamIdRequest: ''
      }
    })
    return {
      all,
      user,
      state,
      task,
      pending,
      completed,
      ...LoadPanel(),
      reminder,
    }
  },
  methods: {

    onAddReminder() {
      switch (this.objectType) {
        case 'CLAIM':
          this.state.reminder.claimIdRequest = this.objectId
          this.state.reminder.cazeIdRequest = ''
          break
        case 'CASE':
          this.state.reminder.cazeIdRequest = this.objectId
          this.state.reminder.claimIdRequest = ''
          break
      }
      this.state.id = ''
      this.state.reminder.description
      this.state.reminder.name = ''
      this.state.reminder.dueDt = new Date().getTime();
      this.state.reminder.assignType = 'USER'
      this.state.reminder.assigneeIdRequest = this.user.id
      this.state.reminder.teamIdRequest = ''
      this.state.title = 'New Reminder'
      const modal = this.$refs.reminderFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    addTask() {
      this.state.id = '';
      this.state.model.name = ''
      this.state.model.status = 'NOT_SET'
      this.state.model.description = ''
      this.state.model.dueDate = new Date().getTime()
      this.state.model.cazeReqId = ''
      this.state.model.claimReqId = ''
      this.state.model.invoiceReqId = ''
      this.state.model.processReqId = ''
      this.state.model.assigneeReqId = this.user.id
      this.state.model.teamReqId = ''
      this.state.model.assignType = 'USER'

      switch (this.objectType) {
        case 'CASE':
          this.state.model.cazeReqId = this.objectId
          break
        case 'CLAIM':
          this.state.model.claimReqId = this.objectId
          break
        case 'INVOICE':
          this.state.model.invoiceReqId = this.objectId
          break
        case 'PROCESS':
          this.state.model.processReqId = this.objectId
          break
        case 'LOG_REPORT':
          this.state.model.logReportReqId = this.objectId
          break
      }
      const modal = this.$refs.formTaskRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.formTaskRef as typeof BaseModal;
      modal.hideBaseModal();
      const all = this.$refs.allTasks as typeof AllTasks
      all.paginationLoad()
      const pendingTasks = this.$refs.pendingTasks as typeof AllTasks
      pendingTasks.paginationLoad()

      const completedTasks = this.$refs.completedTasks as typeof AllTasks
      completedTasks.paginationLoad()
      if (this.task.data.id) {
        store.dispatch(Actions.LOAD_TASK, this.task.data.id)
        const audit = this.$refs.taskAuditsRef as typeof Audits;
        audit.paginationLoad();
      }
    },

    editTask(task) {
      this.state.id = task.id;
      this.state.title = 'Edit Task'
      this.state.model.name = task.name
      this.state.model.status = task.status
      this.state.model.description = task.description
      this.state.model.dueDate = task.dueDate
      this.state.model.cazeReqId = task.cazeId
      this.state.model.claimReqId = task.claimId
      this.state.model.invoiceReqId = task.invoiceId
      this.state.model.processReqId = task.processId
      this.state.model.logReportReqId = task.logReportReqId
      this.state.model.assigneeReqId = task.assignee ? task.assignee?.id : ''
      this.state.model.teamReqId = task.team ? task.team.id : ''
      this.state.model.assignType = task.assignee?.id ? 'USER' : 'TEAM'
      const modal = this.$refs.formTaskRef as typeof BaseModal;
      modal.showBaseModal();
    },

    completeTask(id) {
      this.state.id = id;
      this.state.complete = {duration: 5, completedNote: ''}
      const modal = this.$refs.completeTaskRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSubmitComplete() {
      this.state.submitting = true
      TaskService.complete(this.state.id, this.state.complete).then(() => {
        store.dispatch(Actions.LOAD_TASK, this.task.data.id)
        const modal = this.$refs.completeTaskRef as typeof BaseModal
        modal.hideBaseModal();
        const audit = this.$refs.taskAuditsRef as typeof Audits;
        audit.paginationLoad();
        const all = this.$refs.allTasks as typeof AllTasks
        all.paginationLoad()
        const pendingTasks = this.$refs.pendingTasks as typeof AllTasks
        pendingTasks.paginationLoad()
        const completedTasks = this.$refs.completedTasks as typeof AllTasks
        completedTasks.paginationLoad()
      }).finally(() => {
        this.state.submitting = false
      })
    },
    deleteTask(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TaskService.delete(id).then(() => {
            const all = this.$refs.allTasks as typeof AllTasks
            all.paginationLoad()
            const pendingTasks = this.$refs.pendingTasks as typeof AllTasks
            pendingTasks.paginationLoad()
            const completedTasks = this.$refs.completedTasks as typeof AllTasks
            completedTasks.paginationLoad()
            store.commit('SET_SHOW_TASK_DETAIL', false)
          })
        }
      })
    },
    onSaveReminder(res) {
      const modal = this.$refs.reminderFormRef as typeof BaseModal;
      modal.hideBaseModal();
      this.reloadListReminder();
      if (this.reminder?.data?.id == res.id) {
        store.dispatch(Actions.LOAD_REMINDER, this.reminder.data.id)
      }
    },

    reloadListReminder() {
      const all = this.$refs["all-reminder"] as typeof AllReminder;
      all.paginationLoad()
      const pending = this.$refs["pendingReminder"] as typeof PendingReminder;
      pending.paginationLoad()
      const complete = this.$refs["completeReminder"] as typeof CompleteReminder;
      complete.paginationLoad()
    },

    onEditReminder(reminder: any) {
      this.state.id = reminder.id
      switch (this.objectType) {
        case 'CLAIM':
          this.state.reminder.claimIdRequest = this.objectId
          this.state.reminder.cazeIdRequest = ''
          break
        case 'CASE':
          this.state.reminder.cazeIdRequest = this.objectId
          this.state.reminder.claimIdRequest = ''
          break
      }
      this.state.reminder.name = reminder.name
      this.state.reminder.description  = reminder.description
      this.state.reminder.dueDt = reminder.dueDt
      this.state.reminder.assignType = reminder?.assignee?.id ? 'USER' : 'TEAM'
      this.state.reminder.assigneeIdRequest = reminder.assignee?.id
      this.state.reminder.teamIdRequest = reminder.team?.id
      this.state.title = 'Update Reminder'
      const modal = this.$refs.reminderFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onCompleteReminder(id) {
      this.state.id = id
      const modal = this.$refs.reminderCompleteForm as typeof BaseModal;
      modal.showBaseModal();

    },
    onCompletedReminder(res) {
      this.reloadListReminder()
      store.dispatch(Actions.LOAD_REMINDER, res.id)
      const modal = this.$refs.reminderCompleteForm as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDeleteReminder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ReminderService.delete(id).then(() => {
            this.reloadListReminder()
            store.commit(Mutations.SET_REMINDER, {show: false, data: {}, loading: false})
          })
        }
      })
    }
  }

})
