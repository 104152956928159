import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-body"
}
const _hoisted_2 = {
  key: 1,
  class: "card-body pt-3 pb-3 ps-3"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_TaskList = _resolveComponent("TaskList")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_ctx.page.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EntityLoading)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.page.total=== 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, " No tasks "))
          : _createCommentVNode("", true),
        _createVNode(_component_TaskList, {
          tasks: _ctx.page.data
        }, null, 8, ["tasks"]),
        (_ctx.page.data.total>0)
          ? (_openBlock(), _createBlock(_component_Pagination, {
              key: 1,
              total: _ctx.page.data.total,
              "current-page": _ctx.filter.paginationPage,
              "item-per-page": _ctx.filter.paginationSize,
              class: "text-lg-center",
              onCurrentChange: _ctx.handleUpdatePage
            }, null, 8, ["total", "current-page", "item-per-page", "onCurrentChange"]))
          : _createCommentVNode("", true)
      ]))
}