
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import store from "@/store";
import Pagination from "@/components/base/paging/Pagination.vue";
import TaskList from "@/views/task/TaskList.vue";

export default defineComponent({
  name: "AllTasks",
  components: {TaskList, Pagination, EntityLoading},
  props: {
    filter: {},
  },
  setup(props) {

    const filterObject = ref(props.filter);
    const page = computed(() => store.state.TaskModule.allPage);
    return {
      page,
      filterObject,
      ...LoadFilterObjects(Actions.LOAD_ALL_TASKS, filterObject.value, ['createdBy', 'assignee', 'team'])
    }
  },
  watch: {
    filter(cb) {
      this.filterObject = cb
      this.updateFilterObject(this.filterObject)
    }
  },

})
