
import {defineComponent} from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import TaskName from "@/views/task/TaskName.vue";

export default defineComponent({
  name: "TaskList",
  components: {TaskName, Avatar, DateTimeFormat},
  props: {
    tasks: {type: Array, default: () => []},
    panel: {default: false}
  },
  methods: {
    onLoad(id) {
      store.dispatch(Actions.LOAD_TASK, id)
    }
  }
})
